const CATEGORY_ID_CIVIL_SERVICES = 1
const CATEGORY_ID_COMMERCE = 2
const CATEGORY_ID_DEFENCE_POLICE_FORCES = 3
const CATEGORY_ID_DESIGN_FINE_ARTS = 4
const CATEGORY_ID_EDUCATION = 5
const CATEGORY_ID_ENGINEER = 6
const CATEGORY_ID_HUMANITITES = 7
const CATEGORY_ID_LAW = 8
const CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS = 9
const CATEGORY_ID_MEDICAL_SCIENCE = 10
const CATEGORY_ID_MISCELLANEOUS = 11
const CATEGORY_ID_PURE_SCIENCES = 12
const CATEGORY_ID_SOCIAL_WORK = 13
const CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY = 14

const PATH_CIVIL_SERVICES_IAS = 'Civil Services/IAS.svg'
const PATH_CIVIL_SERVICES_IFS = 'Civil Services/IFS.svg'
const PATH_CIVIL_SERVICES_IAAS = 'Civil Services/Indian Audit and Accounts Service (IAAS).svg'
const PATH_CIVIL_SERVICES_ICAS = 'Civil Services/Indian Civil Accounts Service (ICAS).svg'
const PATH_CIVIL_SERVICES_ICLS = 'Civil Services/Indian Corporate Law Service (ICLS).svg'
const PATH_CIVIL_SERVICES_IIS = 'Civil Services/Indian Information Service (IIS).svg'
const PATH_CIVIL_SERVICES_IPOS = 'Civil Services/Indian Postal Service (IPoS).svg'
const PATH_CIVIL_SERVICES_IRAS = 'Civil Services/Indian Railway Accounts Service (IRAS).svg'
const PATH_CIVIL_SERVICES_IRS_IT = 'Civil Services/Indian Revenue Service (IRS-IT).svg'
const PATH_CIVIL_SERVICES_IPS = 'Civil Services/IPS.svg'
const PATH_COMMERCE_ACCOUNTANT = 'Commerce/Accountant.svg'
const PATH_COMMERCE_BANK_OFFICER = 'Commerce/Bank Officer .svg'
const PATH_COMMERCE_CFA = 'Commerce/CFA.svg'
const PATH_COMMERCE_ECONOMIST = 'Commerce/Economist.svg'
const PATH_COMMERCE_ENTREPRENEUR = 'Commerce/Entrepreneur .svg'
const PATH_COMMERCE_FOREIGN_TRADE = 'Commerce/Foreign Trade.svg'
const PATH_COMMERCE_IB_SB = 'Commerce/Investment Banking and Stock Brokering.svg'
const PATH_COMMERCE_MARKETING_MANAGER = 'Commerce/Marketing Manager.svg'
const PATH_COMMERCE_PORTFOLIO_MANAGER = 'Commerce/Portfolio Manager.svg'
const PATH_COMMERCE_SALES_MANAGER = 'Commerce/Sales Manager.svg'
const PATH_DEFENCE_POLICE_FORCES_AIR_FORCE = 'Defence & Police Forces/Air Force.svg'
const PATH_DEFENCE_POLICE_FORCES_ARMY = 'Defence & Police Forces/Army.svg'
const PATH_DEFENCE_POLICE_FORCES_ASSAM_RIFFLE = 'Defence & Police Forces/Assam Riffle.svg'
const PATH_DEFENCE_POLICE_FORCES_BSF = 'Defence & Police Forces/BSF.svg'
const PATH_DEFENCE_POLICE_FORCES_CISF = 'Defence & Police Forces/CISF.svg'
const PATH_DEFENCE_POLICE_FORCES_CRPF = 'Defence & Police Forces/CRPF.svg'
const PATH_DEFENCE_POLICE_FORCES_ITBP = 'Defence & Police Forces/ITBP.svg'
const PATH_DEFENCE_POLICE_FORCES_NAVY = 'Defence & Police Forces/Navy.svg'
const PATH_DEFENCE_POLICE_FORCES_POLICE_FORCE = 'Defence & Police Forces/Police Force.svg'
const PATH_DEFENCE_POLICE_FORCES_SSB = 'Defence & Police Forces/SSB.svg'
const PATH_DESIGN_FINE_ARTS_ANIMATION_DESIGNER = 'Design and Fine Arts/Animation Designer.svg'
const PATH_DESIGN_FINE_ARTS_AUTOMOBILE_DESIGNER = 'Design and Fine Arts/Automobile Designer.svg'
const PATH_DESIGN_FINE_ARTS_FASHION_DESIGNER = 'Design and Fine Arts/Fashion Designer.svg'
const PATH_DESIGN_FINE_ARTS_GRAPHIC_DESIGNER = 'Design and Fine Arts/Graphic Designer.svg'
const PATH_DESIGN_FINE_ARTS_INTERIOR_DESIGNER = 'Design and Fine Arts/Interior Designer.svg'
const PATH_DESIGN_FINE_ARTS_PAINTER = 'Design and Fine Arts/Painter.svg'
const PATH_DESIGN_FINE_ARTS_PRODUCT_DESIGNER = 'Design and Fine Arts/Product Designer.svg'
const PATH_DESIGN_FINE_ARTS_SCULPTOR = 'Design and Fine Arts/Sculptor.svg'
const PATH_DESIGN_FINE_ARTS_UX_DESIGNER = 'Design and Fine Arts/UX Designer.svg'
const PATH_DESIGN_FINE_ARTS_WEB_DESIGNER = 'Design and Fine Arts/Website Designer.svg'
const PATH_EDUCATION_ADMINISTRATOR = 'Education/Administrator .svg'
const PATH_EDUCATION_COUNSELLOR = 'Education/Counsellor.svg'
const PATH_EDUCATION_LAB_TECHNICIAN = 'Education/Lab Technician.svg'
const PATH_EDUCATION_LIBRARIAN = 'Education/Librarian.svg'
const PATH_EDUCATION_PROFESSOR = 'Education/Professor.svg'
const PATH_EDUCATION_PSYCHOLOGIST = 'Education/Psychologist.svg'
const PATH_EDUCATION_SPECIAL_EDUCATION = 'Education/Special Education.svg'
const PATH_EDUCATION_SPORTS_COACH = 'Education/Sports Coach.svg'
const PATH_EDUCATION_TEACHER = 'Education/Teacher.svg'
const PATH_EDUCATION_TUTOR = 'Education/Tutor.svg'
const PATH_ENGINEER_AEROSPACE = 'Engineer/Aerospace .svg'
const PATH_ENGINEER_ARCHITECHTURE = 'Engineer/Architechture.svg'
const PATH_ENGINEER_BIOTECHNOLOGY = 'Engineer/Biotechnology.svg'
const PATH_ENGINEER_CIVIL = 'Engineer/Civil.svg'
const PATH_ENGINEER_COMPUTER_SCIENCES = 'Engineer/Computer Sciences.svg'
const PATH_ENGINEER_ELECTRICAL = 'Engineer/Electrical.svg'
const PATH_ENGINEER_ELECTRONICS = 'Engineer/Electronics.svg'
const PATH_ENGINEER_MECHANICAL = 'Engineer/Mechanical.svg'
const PATH_ENGINEER_METALLURGY = 'Engineer/Metallurgy.svg'
const PATH_ENGINEER_MINING = 'Engineer/Mining .svg'
const PATH_HUMANITIES_ARCHEOLOGIST = 'Humanities/Archeologist.svg'
const PATH_HUMANITIES_GEOGRAPHY = 'Humanities/Geography.svg'
const PATH_HUMANITIES_HISTORIAN = 'Humanities/Historian.svg'
const PATH_HUMANITIES_HR_MANAGER = 'Humanities/Human Resource Manager.svg'
const PATH_HUMANITIES_LANGUAGE_EXPERT = 'Humanities/Language Expert.svg'
const PATH_HUMANITIES_LITERATURE_EXPERT = 'Humanities/Literature Expert.svg'
const PATH_HUMANITIES_PHILOSOPHER = 'Humanities/philosopher.svg'
const PATH_HUMANITIES_POLITICAL_SCIENCES = 'Humanities/Political Sciences.svg'
const PATH_HUMANITIES_PUBLIC_POLICY_MAKER = 'Humanities/Public Policy Maker.svg'
const PATH_HUMANITIES_SOCIOLOGIST = 'Humanities/Sociologist.svg'
const PATH_LAW_CIVIL_LAWYER = 'Law/Civil Lawyer.svg'
const PATH_LAW_CORPORATE_LAWYER = 'Law/Corporate Lawyer.svg'
const PATH_LAW_CRIMINAL_LAWYER = 'Law/Criminal Lawyer.svg'
const PATH_LAW_DATA_PROTECTION_LAWYER = 'Law/Data Protection Lawyer.svg'
const PATH_LAW_FAMILY_LAWYER = 'Law/Family Lawyer.svg'
const PATH_LAW_GENERAL_PRACTICE_LAWYER = 'Law/General Practise Lawyer.svg'
const PATH_LAW_IMMIGRATION_LAWYER = 'Law/Immigration Lawyer.svg'
const PATH_LAW_IPR_LAWYER = 'Law/IPR Lawyer.svg'
const PATH_LAW_LABOUR_LAWYER = 'Law/Labour Law.svg'
const PATH_LAW_TAXATION_LAWYER = 'Law/Taxation Lawyer.svg'
const PATH_MASS_MEDIA_ACTOR = 'Mass Media, Journalism, Film and Performing Arts/Actor.svg'
const PATH_MASS_MEDIA_BLOGGER = 'Mass Media, Journalism, Film and Performing Arts/Blogger.svg'
const PATH_MASS_MEDIA_CHOREOGRAPHER = 'Mass Media, Journalism, Film and Performing Arts/Choreographer.svg'
const PATH_MASS_MEDIA_COMEDIAN = 'Mass Media, Journalism, Film and Performing Arts/comedian.svg'
const PATH_MASS_MEDIA_DIRECTOR = 'Mass Media, Journalism, Film and Performing Arts/Director.svg'
const PATH_MASS_MEDIA_JOURNALIST = 'Mass Media, Journalism, Film and Performing Arts/Journalist.svg'
const PATH_MASS_MEDIA_PRODUCER = 'Mass Media, Journalism, Film and Performing Arts/Producer.svg'
const PATH_MASS_MEDIA_RADIO_JOCKEY = 'Mass Media, Journalism, Film and Performing Arts/Radio Jockey.svg'
const PATH_MASS_MEDIA_SCRIPT_WRITER = 'Mass Media, Journalism, Film and Performing Arts/Script Writer.svg'
const PATH_MASS_MEDIA_SINGER = 'Mass Media, Journalism, Film and Performing Arts/Singer.svg'
const PATH_MEDICAL_SCIENCE_AYURVEDIC = 'Medical Science/Ayurvedic.svg'
const PATH_MEDICAL_SCIENCE_DENTIST = 'Medical Science/Dentist.svg'
const PATH_MEDICAL_SCIENCE_GENERAL_MEDICINE = 'Medical Science/General Medicine.svg'
const PATH_MEDICAL_SCIENCE_HOMEOPATHY = 'Medical Science/Homeopathy.svg'
const PATH_MEDICAL_SCIENCE_NEUROLOGY = 'Medical Science/Neurology.svg'
const PATH_MEDICAL_SCIENCE_NURSE = 'Medical Science/Nurse.svg'
const PATH_MEDICAL_SCIENCE_PARAMEDICAL = 'Medical Science/Paramedical.svg'
const PATH_MEDICAL_SCIENCE_PHARMACIST = 'Medical Science/Pharmacist.svg'
const PATH_MEDICAL_SCIENCE_PHYSIOTHERAPIST = 'Medical Science/Physiotherapist.svg'
const PATH_MEDICAL_SCIENCE_VETERINARY = 'Medical Science/Veterinary.svg'
const PATH_MISCELLANEOUS_ATHLETE = 'Miscellaneous/Athlete.svg'
const PATH_MISCELLANEOUS_FITNESS = 'Miscellaneous/Fitness.svg'
const PATH_MISCELLANEOUS_FOOD_TESTER = 'Miscellaneous/Food Tester.svg'
const PATH_MISCELLANEOUS_HAIR_STYLIST = 'Miscellaneous/Hair Stylist.svg'
const PATH_MISCELLANEOUS_MAKE_UP_ARTIST = 'Miscellaneous/Make-up Artist.svg'
const PATH_MISCELLANEOUS_NUTRITIONIST = 'Miscellaneous/Nutritionist.svg'
const PATH_MISCELLANEOUS_PHOTOGRAPHY = 'Miscellaneous/Photography.svg'
const PATH_MISCELLANEOUS_POLITICIAN = 'Miscellaneous/Politician.svg'
const PATH_MISCELLANEOUS_SPORTS_PLAYER = 'Miscellaneous/Sports Player.svg'
const PATH_MISCELLANEOUS_TATTOO_ARTIST = 'Miscellaneous/Tattoo Artist.svg'
const PATH_PURE_SCIENCES_AGRICULTURIST = 'Pure Sciences/Agriculatist.svg'
const PATH_PURE_SCIENCES_ASTRONOMIST = 'Pure Sciences/Astronomist.svg'
const PATH_PURE_SCIENCES_BIOCHEMIST = 'Pure Sciences/Biochemist.svg'
const PATH_PURE_SCIENCES_BOTANIST = 'Pure Sciences/Botanist.svg'
const PATH_PURE_SCIENCES_CHEMIST = 'Pure Sciences/Chemist.svg'
const PATH_PURE_SCIENCES_GEOLOGIST = 'Pure Sciences/Geologist.svg'
const PATH_PURE_SCIENCES_MATHEMATICIAN = 'Pure Sciences/Mathematician.svg'
const PATH_PURE_SCIENCES_OCEANOLOGIST = 'Pure Sciences/Oceanologist.svg'
const PATH_PURE_SCIENCES_PHYSICIST = 'Pure Sciences/Physicist .svg'
const PATH_PURE_SCIENCES_ZOOLOGIST = 'Pure Sciences/Zoologist.svg'
const PATH_SOCIAL_WORK_CFSS_SOCIAL_WORKER = 'Social Work/Child, Family and School Social Worker.svg'
const PATH_SOCIAL_WORK_COMMUNITY_SOCIAL_WORKER = 'Social Work/Community Social Worker.svg'
const PATH_SOCIAL_WORK_HEALTHCARE_SOCIAL_WORKER = 'Social Work/Healthcare Social Worker.svg'
const PATH_SOCIAL_WORK_H_PC_SOCIAL_WORKER = 'Social Work/Hospice and Palliative Care Social Worker.svg'
const PATH_SOCIAL_WORK_HSW_SOCIAL_WORKER = 'Social Work/Hygiene, Sanitation and Water Social Work .svg'
const PATH_SOCIAL_WORK_LS_SOCIAL_WORKER = 'Social Work/Labour Support Social Worker.svg'
const PATH_SOCIAL_WORK_MV_CARE_SOCIAL_WORKER = 'Social Work/Military & Veteran Care Social Worker.svg'
const PATH_SOCIAL_WORK_PSYCHIATRIC_SOCIAL_WORKER = 'Social Work/Psychiatric Social Worker.svg'
const PATH_SOCIAL_WORK_SOCIAL_POLICY_ANALYST = 'Social Work/social policy analyst.svg'
const PATH_SOCIAL_WORK_SA_SOCIAL_WORKER = 'Social Work/Substance Abuse Social Worker.svg'
const PATH_TRAVEL_TOURISM_HOSPITALITY_AIR_HOSTESS_STEWARD = 'Travel, Tourism and Hospitality/Air Hostess Steward.svg'
const PATH_TRAVEL_TOURISM_HOSPITALITY_BARTENDER = 'Travel, Tourism and Hospitality/Bartender.svg'
const PATH_TRAVEL_TOURISM_HOSPITALITY_CHEF = 'Travel, Tourism and Hospitality/Chef.svg'
const PATH_TRAVEL_TOURISM_HOSPITALITY_COMMERCIAL_PILOT = 'Travel, Tourism and Hospitality/Commercial Pilot.svg'
const PATH_TRAVEL_TOURISM_HOSPITALITY_EVENT_MANAGER = 'Travel, Tourism and Hospitality/Event Manager.svg'
const PATH_TRAVEL_TOURISM_HOSPITALITY_FRONT_OFFICE_RECEPTIONIST = 'Travel, Tourism and Hospitality/Front Office Receptionist.svg'
const PATH_TRAVEL_TOURISM_HOSPITALITY_HOUSEKEEPING = 'Travel, Tourism and Hospitality/Housekeeping.svg'
const PATH_TRAVEL_TOURISM_HOSPITALITY_OPERATIONS_MANAGER = 'Travel, Tourism and Hospitality/Operations Manager.svg'
const PATH_TRAVEL_TOURISM_HOSPITALITY_TRAVEL_PLANNER = 'Travel, Tourism and Hospitality/Travel Planner.svg'
const PATH_TRAVEL_TOURISM_HOSPITALITY_TRAVEL_VLOGGER = 'Travel, Tourism and Hospitality/Travel Vlogger.svg'

const qBank = [
    {
        option_one_image: PATH_ENGINEER_COMPUTER_SCIENCES,
        option_one_title: 'Computer Science Engineer',
        option_one_description: 'Someone who uses his knowledge in computer systems both in hardware and software to design and operate computers.',
        option_one_category_id: CATEGORY_ID_ENGINEER,
        option_two_image: PATH_MEDICAL_SCIENCE_GENERAL_MEDICINE,
        option_two_title: 'General Medicine Doctor',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
        option_three_image: PATH_DESIGN_FINE_ARTS_GRAPHIC_DESIGNER,
        option_three_title: 'Graphic Designer',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
        option_four_image: PATH_EDUCATION_TEACHER,
        option_four_title: 'Teacher',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_EDUCATION,
    },
    {
        option_one_image: PATH_LAW_CORPORATE_LAWYER,
        option_one_title: 'Corporate Lawyer',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_LAW,
        option_two_image: PATH_CIVIL_SERVICES_IAS,
        option_two_title: 'IAS',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_CIVIL_SERVICES,
        option_three_image: PATH_SOCIAL_WORK_SA_SOCIAL_WORKER,
        option_three_title: 'Substance Abuse Social Worker',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_SOCIAL_WORK,
        option_four_image: PATH_TRAVEL_TOURISM_HOSPITALITY_CHEF,
        option_four_title: 'Chef',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
    },
    {
        option_one_image: PATH_MASS_MEDIA_JOURNALIST,
        option_one_title: 'Journalist',
        option_one_description: 'The Air Force focuses on flying, but only a small fraction of personnel actually fly. Most airmen and airwomen work on flight support missions, handling base affairs, protecting bases, constructing new airstrips, guarding missile sites, even doing rescues.',
        option_one_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_two_image: PATH_COMMERCE_ACCOUNTANT,
        option_two_title: 'Accountant',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_COMMERCE,
        option_three_image: PATH_PURE_SCIENCES_BOTANIST,
        option_three_title: 'Botanist',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_PURE_SCIENCES,
        option_four_image: PATH_HUMANITIES_HISTORIAN,
        option_four_title: 'Historian',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_HUMANITITES,
    },
    {
        option_one_image: PATH_DEFENCE_POLICE_FORCES_ARMY,
        option_one_title: 'Army Officer',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
        option_two_image: PATH_MISCELLANEOUS_MAKE_UP_ARTIST,
        option_two_title: 'Make-up Artist',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_MISCELLANEOUS,
        option_three_image: PATH_ENGINEER_ELECTRONICS,
        option_three_title: 'Electronics and Communication Engineer',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_ENGINEER,
        option_four_image: PATH_MEDICAL_SCIENCE_NEUROLOGY,
        option_four_title: 'Neurologist',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
    },
    {
        option_one_image: PATH_DESIGN_FINE_ARTS_WEB_DESIGNER,
        option_one_title: 'Web Designer',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
        option_two_image: PATH_EDUCATION_PROFESSOR,
        option_two_title: 'Professor',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_EDUCATION,
        option_three_image: PATH_LAW_CRIMINAL_LAWYER,
        option_three_title: 'Criminal Lawyer',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_LAW,
        option_four_image: PATH_CIVIL_SERVICES_IPS,
        option_four_title: 'IPS',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_CIVIL_SERVICES,
    },
    {
        option_one_image: PATH_SOCIAL_WORK_COMMUNITY_SOCIAL_WORKER,
        option_one_title: 'Community Social Worker',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_SOCIAL_WORK,
        option_two_image: PATH_TRAVEL_TOURISM_HOSPITALITY_FRONT_OFFICE_RECEPTIONIST,
        option_two_title: 'Receptionist',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
        option_three_image: PATH_MASS_MEDIA_BLOGGER,
        option_three_title: 'Blogger',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_four_image: PATH_COMMERCE_ENTREPRENEUR,
        option_four_title: 'Entrepreneur',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_COMMERCE,
    },
    {
        option_one_image: PATH_PURE_SCIENCES_ZOOLOGIST,
        option_one_title: 'Zoologist',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_PURE_SCIENCES,
        option_two_image: PATH_HUMANITIES_GEOGRAPHY,
        option_two_title: 'Geographer',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_HUMANITITES,
        option_three_image: PATH_DEFENCE_POLICE_FORCES_NAVY,
        option_three_title: 'Navy Officer',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
        option_four_image: PATH_MISCELLANEOUS_POLITICIAN,
        option_four_title: 'Politician',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_MISCELLANEOUS,
    },
    {
        option_one_image: PATH_ENGINEER_ELECTRICAL,
        option_one_title: 'Electrical Engnieer',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_ENGINEER,
        option_two_image: PATH_MEDICAL_SCIENCE_DENTIST,
        option_two_title: 'Dentist',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
        option_three_image: PATH_DESIGN_FINE_ARTS_UX_DESIGNER,
        option_three_title: 'UX Designer',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
        option_four_image: PATH_EDUCATION_ADMINISTRATOR,
        option_four_title: 'School Administrator',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_EDUCATION,
    },
    {
        option_one_image: PATH_MASS_MEDIA_RADIO_JOCKEY,
        option_one_title: 'Radio Jockey',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_two_image: PATH_COMMERCE_MARKETING_MANAGER,
        option_two_title: 'Marketing Manager',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_COMMERCE,
        option_three_image: PATH_PURE_SCIENCES_GEOLOGIST,
        option_three_title: 'Geologist',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_PURE_SCIENCES,
        option_four_image: PATH_HUMANITIES_ARCHEOLOGIST,
        option_four_title: 'Archeologist',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_PURE_SCIENCES,
    },
    {
        option_one_image: PATH_LAW_CIVIL_LAWYER,
        option_one_title: 'Civil Lawyer',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_LAW,
        option_two_image: PATH_CIVIL_SERVICES_IFS,
        option_two_title: 'IFS',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_CIVIL_SERVICES,
        option_three_image: PATH_SOCIAL_WORK_H_PC_SOCIAL_WORKER,
        option_three_title: 'Palliative Care',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_SOCIAL_WORK,
        option_four_image: PATH_TRAVEL_TOURISM_HOSPITALITY_TRAVEL_VLOGGER,
        option_four_title: 'Travel Blogger',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
    },
    {
        option_one_image: PATH_DEFENCE_POLICE_FORCES_AIR_FORCE,
        option_one_title: 'Airforce Officer',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
        option_two_image: PATH_MISCELLANEOUS_PHOTOGRAPHY,
        option_two_title: 'Photographer',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_MISCELLANEOUS,
        option_three_image: PATH_ENGINEER_AEROSPACE,
        option_three_title: 'Aerospace Engineer',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_ENGINEER,
        option_four_image: PATH_MEDICAL_SCIENCE_AYURVEDIC,
        option_four_title: 'Ayurvedic',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
    },
    {
        option_one_image: PATH_DESIGN_FINE_ARTS_INTERIOR_DESIGNER,
        option_one_title: 'Interior Designer',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
        option_two_image: PATH_EDUCATION_LIBRARIAN,
        option_two_title: 'Librarian',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_EDUCATION,
        option_three_image: PATH_LAW_FAMILY_LAWYER,
        option_three_title: 'Family Lawyer',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_LAW,
        option_four_image: PATH_CIVIL_SERVICES_IAAS,
        option_four_title: 'Indian Audits and Accounts Service (IAAS)',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_CIVIL_SERVICES,
    },
    {
        option_one_image: PATH_SOCIAL_WORK_MV_CARE_SOCIAL_WORKER,
        option_one_title: 'Military Veteran Social Worker',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_SOCIAL_WORK,
        option_two_image: PATH_TRAVEL_TOURISM_HOSPITALITY_AIR_HOSTESS_STEWARD,
        option_two_title: 'Air Hostess/Steward',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
        option_three_image: PATH_MASS_MEDIA_ACTOR,
        option_three_title: 'Actor',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_four_image: PATH_COMMERCE_IB_SB,
        option_four_title: 'Insurance and Banking/Stock Broker',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_COMMERCE,
    },
    {
        option_one_image: PATH_PURE_SCIENCES_PHYSICIST,
        option_one_title: 'Physicist',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_PURE_SCIENCES,
        option_two_image: PATH_HUMANITIES_POLITICAL_SCIENCES,
        option_two_title: 'Political Science',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_HUMANITITES,
        option_three_image: PATH_DEFENCE_POLICE_FORCES_CRPF,
        option_three_title: 'CRPF',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
        option_four_image: PATH_MISCELLANEOUS_SPORTS_PLAYER,
        option_four_title: 'Sports Player',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_MISCELLANEOUS,
    },
    {
        option_one_image: PATH_MISCELLANEOUS_ATHLETE,
        option_one_title: 'Athlete',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_MISCELLANEOUS,
        option_two_image: PATH_DEFENCE_POLICE_FORCES_CISF,
        option_two_title: 'CISF',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
        option_three_image: PATH_HUMANITIES_SOCIOLOGIST,
        option_three_title: 'Sociologist',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_HUMANITITES,
        option_four_image: PATH_PURE_SCIENCES_CHEMIST,
        option_four_title: 'Chemist',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_PURE_SCIENCES,
    },
    {
        option_one_image: PATH_COMMERCE_BANK_OFFICER,
        option_one_title: 'Bank Officer/ Manager',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_COMMERCE,
        option_two_image: PATH_MASS_MEDIA_DIRECTOR,
        option_two_title: 'Movie Director',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_three_image: PATH_TRAVEL_TOURISM_HOSPITALITY_OPERATIONS_MANAGER,
        option_three_title: 'Hotel Operations Manager',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
        option_four_image: PATH_SOCIAL_WORK_CFSS_SOCIAL_WORKER,
        option_four_title: 'School Social Worker',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_SOCIAL_WORK,
    },
    {
        option_one_image: PATH_CIVIL_SERVICES_ICAS,
        option_one_title: 'ICAS',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_CIVIL_SERVICES,
        option_two_image: PATH_LAW_DATA_PROTECTION_LAWYER,
        option_two_title: 'Data Protection Lawyer',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_LAW,
        option_three_image: PATH_EDUCATION_TUTOR,
        option_three_title: 'TUTOR',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_EDUCATION,
        option_four_image: PATH_DESIGN_FINE_ARTS_FASHION_DESIGNER,
        option_four_title: 'Fashion Designer',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
    },
    {
        option_one_image: PATH_MEDICAL_SCIENCE_HOMEOPATHY,
        option_one_title: 'Homeopathy Doctor',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
        option_two_image: PATH_ENGINEER_MECHANICAL,
        option_two_title: 'Mechanical Engineer',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_ENGINEER,
        option_three_image: PATH_DESIGN_FINE_ARTS_AUTOMOBILE_DESIGNER,
        option_three_title: 'Automobile Designer',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
        option_four_image: PATH_EDUCATION_LAB_TECHNICIAN,
        option_four_title: 'Lab Technician',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_EDUCATION,
    },
    {
        option_one_image: PATH_ENGINEER_CIVIL,
        option_one_title: 'Civil Engineer',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_ENGINEER,
        option_two_image: PATH_LAW_IPR_LAWYER,
        option_two_title: 'IPR Lawyer',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_LAW,
        option_three_image: PATH_MEDICAL_SCIENCE_PHARMACIST,
        option_three_title: 'Pharmacist',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
        option_four_image: PATH_CIVIL_SERVICES_ICLS,
        option_four_title: 'Indian Corporate Law Service (ICLS)',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_CIVIL_SERVICES,
    },
    {
        option_one_image: PATH_SOCIAL_WORK_PSYCHIATRIC_SOCIAL_WORKER,
        option_one_title: 'Psychiatric Social Worker',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_SOCIAL_WORK,
        option_two_image: PATH_TRAVEL_TOURISM_HOSPITALITY_HOUSEKEEPING,
        option_two_title: 'Housekeeping',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
        option_three_image: PATH_MASS_MEDIA_PRODUCER,
        option_three_title: 'Film Producer',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_four_image: PATH_COMMERCE_CFA,
        option_four_title: 'CFA',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_COMMERCE,
    },
    {
        option_one_image: PATH_PURE_SCIENCES_MATHEMATICIAN,
        option_one_title: 'Mathematician',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_PURE_SCIENCES,
        option_two_image: PATH_HUMANITIES_LITERATURE_EXPERT,
        option_two_title: 'Literature Expert',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_HUMANITITES,
        option_three_image: PATH_DEFENCE_POLICE_FORCES_BSF,
        option_three_title: 'Border Security Force (BSF)',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
        option_four_image: PATH_MISCELLANEOUS_FOOD_TESTER,
        option_four_title: 'Food Tester',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_MISCELLANEOUS,
    },
    {
        option_one_image: PATH_ENGINEER_METALLURGY,
        option_one_title: 'Metallurgical Engineer',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_ENGINEER,
        option_two_image: PATH_MEDICAL_SCIENCE_PARAMEDICAL,
        option_two_title: 'Paramedical Doctor',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
        option_three_image: PATH_DESIGN_FINE_ARTS_PRODUCT_DESIGNER,
        option_three_title: 'Product Designer',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
        option_four_image: PATH_EDUCATION_SPECIAL_EDUCATION,
        option_four_title: 'Special Education Teacher',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_EDUCATION,
    },
    {
        option_one_image: PATH_LAW_TAXATION_LAWYER,
        option_one_title: 'Tax Lawyer',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_LAW,
        option_two_image: PATH_CIVIL_SERVICES_IIS,
        option_two_title: 'IIS',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_CIVIL_SERVICES,
        option_three_image: PATH_SOCIAL_WORK_HEALTHCARE_SOCIAL_WORKER,
        option_three_title: 'Healthcare',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_SOCIAL_WORK,
        option_four_image: PATH_TRAVEL_TOURISM_HOSPITALITY_BARTENDER,
        option_four_title: 'Bartender',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
    },
    {
        option_one_image: PATH_MASS_MEDIA_CHOREOGRAPHER,
        option_one_title: 'Choreographer',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_two_image: PATH_DEFENCE_POLICE_FORCES_ITBP,
        option_two_title: 'Indo – Tibetan Border Police Force (ITBP)',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
        option_three_image: PATH_MISCELLANEOUS_FITNESS,
        option_three_title: 'Fitness Expert',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_MISCELLANEOUS,
        option_four_image: PATH_ENGINEER_MINING,
        option_four_title: 'Mining Engineer',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_ENGINEER,
    },
    {
        option_one_image: PATH_COMMERCE_FOREIGN_TRADE,
        option_one_title: 'Insurance agent/ Foreign Trade',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_COMMERCE,
        option_two_image: PATH_PURE_SCIENCES_BIOCHEMIST,
        option_two_title: 'Biochemist',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_PURE_SCIENCES,
        option_three_image: PATH_HUMANITIES_LANGUAGE_EXPERT,
        option_three_title: 'Language Expert',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_HUMANITITES,
        option_four_image: PATH_LAW_GENERAL_PRACTICE_LAWYER,
        option_four_title: 'General Practice Lawyer',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_LAW,
    },
    {
        option_one_image: PATH_MEDICAL_SCIENCE_VETERINARY,
        option_one_title: 'Veterinary Doctor',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
        option_two_image: PATH_SOCIAL_WORK_LS_SOCIAL_WORKER,
        option_two_title: 'Labor Support Social Worker',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_SOCIAL_WORK,
        option_three_image: PATH_MASS_MEDIA_SINGER,
        option_three_title: 'Singer',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_four_image: PATH_COMMERCE_ECONOMIST,
        option_four_title: 'Economist',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_COMMERCE,
    },
    {
        option_one_image: PATH_DESIGN_FINE_ARTS_ANIMATION_DESIGNER,
        option_one_title: 'Animation Designer',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
        option_two_image: PATH_MISCELLANEOUS_TATTOO_ARTIST,
        option_two_title: 'Tattoo Artist',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_MISCELLANEOUS,
        option_three_image: PATH_DEFENCE_POLICE_FORCES_ASSAM_RIFFLE,
        option_three_title: 'Assam Riffle Officer',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
        option_four_image: PATH_HUMANITIES_PUBLIC_POLICY_MAKER,
        option_four_title: 'Public Policy Maker',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_HUMANITITES,
    },
    {
        option_one_image: PATH_EDUCATION_COUNSELLOR,
        option_one_title: 'Counsellor',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_EDUCATION,
        option_two_image: PATH_CIVIL_SERVICES_IPOS,
        option_two_title: 'Indian Postal Service (IPoS)',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_CIVIL_SERVICES,
        option_three_image: PATH_TRAVEL_TOURISM_HOSPITALITY_EVENT_MANAGER,
        option_three_title: 'Event Manager',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
        option_four_image: PATH_PURE_SCIENCES_AGRICULTURIST,
        option_four_title: 'Agriculturist',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_PURE_SCIENCES,
    },
    {
        option_one_image: PATH_ENGINEER_ARCHITECHTURE,
        option_one_title: 'Architect',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_ENGINEER,
        option_two_image: PATH_TRAVEL_TOURISM_HOSPITALITY_COMMERCIAL_PILOT,
        option_two_title: 'Pilot',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
        option_three_image: PATH_CIVIL_SERVICES_IRAS,
        option_three_title: 'Indian Railway Accounts Service',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_CIVIL_SERVICES,
        option_four_image: PATH_SOCIAL_WORK_SOCIAL_POLICY_ANALYST,
        option_four_title: 'Social Policy Analyst',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_SOCIAL_WORK,
    },
    {
        option_one_image: PATH_MASS_MEDIA_SCRIPT_WRITER,
        option_one_title: 'Script Writer',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_two_image: PATH_COMMERCE_SALES_MANAGER,
        option_two_title: 'Sales Manager',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_COMMERCE,
        option_three_image: PATH_DESIGN_FINE_ARTS_PAINTER,
        option_three_title: 'Painter',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
        option_four_image: PATH_MEDICAL_SCIENCE_PHYSIOTHERAPIST,
        option_four_title: 'Physiotherapist',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
    },
    {
        option_one_image: PATH_EDUCATION_PSYCHOLOGIST,
        option_one_title: 'Psychologist',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_EDUCATION,
        option_two_image: PATH_LAW_IMMIGRATION_LAWYER,
        option_two_title: 'Immigration Lawyer',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_LAW,
        option_three_image: PATH_PURE_SCIENCES_OCEANOLOGIST,
        option_three_title: 'Oceanology',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_PURE_SCIENCES,
        option_four_image: PATH_HUMANITIES_PHILOSOPHER,
        option_four_title: 'Philosopher',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_HUMANITITES,
    },
    {
        option_one_image: PATH_MISCELLANEOUS_HAIR_STYLIST,
        option_one_title: 'Hair Stylist',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_MISCELLANEOUS,
        option_two_image: PATH_MASS_MEDIA_COMEDIAN,
        option_two_title: 'Comedian',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_MASS_MEDIA_JOURNALISM_FILM_PERFORMING_ARTS,
        option_three_image: PATH_CIVIL_SERVICES_IRS_IT,
        option_three_title: 'Indian Revenue Service (IRS)',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_CIVIL_SERVICES,
        option_four_image: PATH_EDUCATION_SPORTS_COACH,
        option_four_title: 'Sports Coach',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_EDUCATION,
    },
    {
        option_one_image: PATH_DEFENCE_POLICE_FORCES_SSB,
        option_one_title: 'SSB',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
        option_two_image: PATH_MISCELLANEOUS_NUTRITIONIST,
        option_two_title: 'Nutritionist',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_MISCELLANEOUS,
        option_three_image: PATH_LAW_LABOUR_LAWYER,
        option_three_title: 'Labour Lawyer',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_LAW,
        option_four_image: PATH_DESIGN_FINE_ARTS_SCULPTOR,
        option_four_title: 'Sculptor',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_DESIGN_FINE_ARTS,
    },
    {
        option_one_image: PATH_ENGINEER_BIOTECHNOLOGY,
        option_one_title: 'Biotechnology',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_ENGINEER,
        option_two_image: PATH_SOCIAL_WORK_HSW_SOCIAL_WORKER,
        option_two_title: 'Hygiene Sanitation Social Worker',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_SOCIAL_WORK,
        option_three_image: PATH_TRAVEL_TOURISM_HOSPITALITY_TRAVEL_PLANNER,
        option_three_title: 'Travel Planner',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_TRAVEL_TOURISM_HOSPITILITY,
        option_four_image: PATH_COMMERCE_PORTFOLIO_MANAGER,
        option_four_title: 'Portfolio Manager',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_COMMERCE,
    },
    {
        option_one_image: PATH_MEDICAL_SCIENCE_NURSE,
        option_one_title: 'Nurse',
        option_one_description: 'CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.',
        option_one_category_id: CATEGORY_ID_MEDICAL_SCIENCE,
        option_two_image: PATH_PURE_SCIENCES_ASTRONOMIST,
        option_two_title: 'Astronomist',
        option_two_description: 'Someone that deals with the design, construction, and maintenance of roads, bridges, canals, dams, airports, sewerage systems, pipelines, structural components of buildings, and railways.',
        option_two_category_id: CATEGORY_ID_PURE_SCIENCES,
        option_three_image: PATH_HUMANITIES_HR_MANAGER,
        option_three_title: 'Human Resource Manager',
        option_three_description: 'Historians are academics and researchers who study events of the past.',
        option_three_category_id: CATEGORY_ID_HUMANITITES,
        option_four_image: PATH_DEFENCE_POLICE_FORCES_POLICE_FORCE,
        option_four_title: 'Police Force',
        option_four_description: 'Athletes and sports competitors play in organized and officiated sports teams nationally or internationally',
        option_four_category_id: CATEGORY_ID_DEFENCE_POLICE_FORCES,
    },
];

export default qBank
