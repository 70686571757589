import React, {Component} from "react";
import ReactDOM from "react-dom";
import "./assets/style.css";
import qBank from "./quizService/index"
import QuestionBox from "./components/QuestionBox";
import Result from "./components/Result";
import Rules from "./components/Rules";

class Quiz extends Component {
    state = {
        startQuiz: false,
        questionBank: [],
        responses: 0,
        option_one: 0,
        option_two: 0,
        option_three: 0,
        option_four: 0,
        current_priority: 0,
        civil_services: [],
        commerce: [],
        defence_police_force: [],
        design_fine_arts: [],
        education: [],
        engineer: [],
        humanities: [],
        law: [],
        mass_media: [],
        medical_science: [],
        miscellaneous: [],
        pure_sciences: [],
        social_work: [],
        travel_tourism: []
    }

    getQuestions = () => {
        var arr = []
        arr.push(qBank[this.state.responses])
        this.setState({
            questionBank: arr,
            questionBankLength: qBank.length
        })
    }

    computeAnswer = () => {
        this.addanswers(this.state.questionBank[0].option_one_category_id, this.state.option_one)
        this.addanswers(this.state.questionBank[0].option_two_category_id, this.state.option_two)
        this.addanswers(this.state.questionBank[0].option_three_category_id, this.state.option_three)
        this.addanswers(this.state.questionBank[0].option_four_category_id, this.state.option_four)
        this.setState({
            responses: this.state.responses < qBank.length ? this.state.responses + 1 : qBank.length,
            option_one: 0,
            option_two: 0,
            option_three: 0,
            option_four: 0,
            current_priority: 0
        })
        var arr = []
        arr.push(qBank[this.state.responses + 1])
        this.setState({
            questionBank: arr,
            questionBankLength: qBank.length
        })
    }

    addanswers = (category_id, value) => {
        switch (category_id) {

            case 1:
                this.setState({
                    civil_services: this.state.civil_services.concat(value)
                })
                break;
            case 2:
                this.setState({
                    commerce: this.state.commerce.concat(value)
                })
                break;
            case 3:
                this.setState({
                    defence_police_force: this.state.defence_police_force.concat(value)
                })
                break;
            case 4:
                this.setState({
                    design_fine_arts: this.state.design_fine_arts.concat(value)
                })
                break;
            case 5:
                this.setState({
                    education: this.state.education.concat(value)
                })
                break;
            case 6:
                this.setState({
                    engineer: this.state.engineer.concat(value)
                })
                break;
            case 7:
                this.setState({
                    humanities: this.state.humanities.concat(value)
                })
                break;
            case 8:
                this.setState({
                    law: this.state.law.concat(value)
                })
                break;
            case 9:
                this.setState({
                    mass_media: this.state.mass_media.concat(value)
                })
                break;
            case 10:
                this.setState({
                    medical_science: this.state.medical_science.concat(value)
                })
                break;
            case 11:
                this.setState({
                    miscellaneous: this.state.miscellaneous.concat(value)
                })
                break;
            case 12:
                this.setState({
                    pure_sciences: this.state.pure_sciences.concat(value)
                })
                break;
            case 13:
                this.setState({
                    social_work: this.state.social_work.concat(value)
                })
                break;
            case 14:
                this.setState({
                    travel_tourism: this.state.travel_tourism.concat(value)
                })
                break;

        }
    }

    playAgain = () => {
        this.setState({
            responses: 0
        })
        this.getQuestions();
    }

    handleClicks = (a) => {
        switch (a) {
            case 101:
                if (this.state.option_one === 0) {
                    this.setState({
                        current_priority: this.state.current_priority + 1,
                        option_one: this.state.current_priority + 1
                    })
                } else {
                    this.setState({
                        current_priority: this.state.current_priority !== 0 ? this.state.current_priority - 1 : 0,
                        option_two: this.state.option_two !== 0 && this.state.option_two > this.state.option_one ? this.state.option_two - 1 : this.state.option_two,
                        option_three: this.state.option_three !== 0 && this.state.option_three > this.state.option_one ? this.state.option_three - 1 : this.state.option_three,
                        option_four: this.state.option_four !== 0 && this.state.option_four > this.state.option_one ? this.state.option_four - 1 : this.state.option_four,
                        option_one: 0,
                    })
                }
                break;
            case 102:
                if (this.state.option_two === 0) {
                    this.setState({
                        current_priority: this.state.current_priority + 1,
                        option_two: this.state.current_priority + 1
                    })
                } else {
                    this.setState({
                        current_priority: this.state.current_priority !== 0 ? this.state.current_priority - 1 : 0,
                        option_one: this.state.option_one !== 0 && this.state.option_one > this.state.option_two ? this.state.option_one - 1 : this.state.option_one,
                        option_three: this.state.option_three !== 0 && this.state.option_three > this.state.option_two ? this.state.option_three - 1 : this.state.option_three,
                        option_four: this.state.option_four !== 0 && this.state.option_four > this.state.option_two ? this.state.option_four - 1 : this.state.option_four,
                        option_two: 0
                    })
                }
                break;
            case 103:
                if (this.state.option_three === 0) {
                    this.setState({
                        current_priority: this.state.current_priority + 1,
                        option_three: this.state.current_priority + 1
                    })
                } else {
                    this.setState({
                        current_priority: this.state.current_priority !== 0 ? this.state.current_priority - 1 : 0,
                        option_one: this.state.option_one !== 0 && this.state.option_one > this.state.option_three ? this.state.option_one - 1 : this.state.option_one,
                        option_two: this.state.option_two !== 0 && this.state.option_two > this.state.option_three ? this.state.option_two - 1 : this.state.option_two,
                        option_four: this.state.option_four !== 0 && this.state.option_four > this.state.option_three ? this.state.option_four - 1 : this.state.option_four,
                        option_three: 0
                    })
                }
                break;
            case 104:
                if (this.state.option_four === 0) {
                    this.setState({
                        current_priority: this.state.current_priority + 1,
                        option_four: this.state.current_priority + 1
                    })
                } else {
                    this.setState({
                        current_priority: this.state.current_priority !== 0 ? this.state.current_priority - 1 : 0,
                        option_one: this.state.option_one !== 0 && this.state.option_one > this.state.option_four ? this.state.option_one - 1 : this.state.option_one,
                        option_two: this.state.option_two !== 0 && this.state.option_two > this.state.option_four ? this.state.option_two - 1 : this.state.option_two,
                        option_three: this.state.option_three !== 0 && this.state.option_three > this.state.option_four ? this.state.option_three - 1 : this.state.option_three,
                        option_four: 0
                    })
                }
                break;

        }
    }

    startPathfinder = (a) => {
        if (a) {
            this.setState({
                startQuiz: true
            })
        } else {
            this.setState({
                startQuiz: false
            })
        }
    }

    componentDidMount() {
        this.getQuestions();
    }

    title = "Rank these career options based on your preference.\nअपनी पसंद के आधार पर इन कैरियर विकल्पों को रैंक करें";

    render() {
        return (
            <div className="container">
                {!this.state.startQuiz && <Rules startFun={this.startPathfinder}/>}
                {this.state.startQuiz &&
                <div>
                    {this.state.responses < qBank.length &&
                    <div className="title">{this.title.split("\n").map((i, key) => {
                        return <div key={key}>{i}</div>;
                    })}
                    </div>
                    }
                    {this.state.responses < qBank.length && this.state.questionBank.map((q, id) => (
                        <div>
                            <QuestionBox
                                option_image={q.option_one_image}
                                option_title={q.option_one_title}
                                option_description={q.option_one_description}
                                priority={this.state.option_one}
                                option_key={101}
                                fun={this.handleClicks}/>
                            <QuestionBox
                                option_image={q.option_two_image}
                                option_title={q.option_two_title}
                                option_description={q.option_two_description}
                                priority={this.state.option_two}
                                option_key={102}
                                fun={this.handleClicks}/>
                            <QuestionBox
                                option_image={q.option_three_image}
                                option_title={q.option_three_title}
                                option_description={q.option_three_description}
                                priority={this.state.option_three}
                                option_key={103}
                                fun={this.handleClicks}/>
                            <QuestionBox
                                option_image={q.option_four_image}
                                option_title={q.option_four_title}
                                option_description={q.option_four_description}
                                priority={this.state.option_four}
                                option_key={104}
                                fun={this.handleClicks}/>
                        </div>
                    ))}
                    {this.state.responses < qBank.length && <button className="nextBtn"
                                                                    onClick={this.state.current_priority === 4 ? this.computeAnswer : null}>
                        <div>Next</div>
                        <div>{this.state.responses + 1}/{qBank.length}</div>
                    </button>}

                    {this.state.responses === qBank.length ? (
                        <Result
                            civil_services={this.state.civil_services}
                            commerce={this.state.commerce}
                            defence_police_force={this.state.defence_police_force}
                            design_fine_arts={this.state.design_fine_arts}
                            education={this.state.education}
                            engineer={this.state.engineer}
                            humanities={this.state.humanities}
                            law={this.state.law}
                            mass_media={this.state.mass_media}
                            medical_science={this.state.medical_science}
                            miscellaneous={this.state.miscellaneous}
                            pure_sciences={this.state.pure_sciences}
                            social_work={this.state.social_work}
                            travel_tourism={this.state.travel_tourism}
                        />) : null}

                </div>
                }
            </div>
        );
    }
}

ReactDOM.render(
    <Quiz/>
    , document.getElementById("root"))