import React, {Component} from "react";
import axios from "axios";
import QuestionBox from "./QuestionBox";
import html2canvas from "html2canvas";
import jsPDF from "jspdf";
import ResultBox from "./ResultBox";
import Canvg from "canvg";

class Result extends Component {
    state = {
        isPostSuccess: true,
        sumAnswers: [],
        sumAnswerDirectory: {},
        valueObject: []
    }

    postQuiz = () => {
        var token = localStorage.getItem("token")
        axios.post('https://probano.herokuapp.com/welcome/4614ada83162eb1e0912159ce915a3a2d972064a/',
            {
                'civil_services': this.props.civil_services,
                'commerce': this.props.commerce,
                'defence_police_force': this.props.defence_police_force,
                'design_fine_arts': this.props.design_fine_arts,
                'education': this.props.education,
                'engineer': this.props.engineer,
                'humanities': this.props.humanities,
                'law': this.props.law,
                'mass_media': this.props.mass_media,
                'medical_science': this.props.medical_science,
                'miscellaneous': this.props.miscellaneous,
                'pure_sciences': this.props.pure_sciences,
                'social_work': this.props.social_work,
                'travel_tourism': this.props.travel_tourism,
            }
            , {headers: {'Authorization': 'Token ' + token}})
            .then(res => {

            })
            .catch(res => {

            })
    }

    calculateSums = () => {
        let civil_services = this.props.civil_services.reduce((a, b) => a + b, 0)
        let commerce = this.props.commerce.reduce((a, b) => a + b, 0)
        let defence_police_force = this.props.defence_police_force.reduce((a, b) => a + b, 0)
        let design_fine_arts = this.props.design_fine_arts.reduce((a, b) => a + b, 0)
        let education = this.props.education.reduce((a, b) => a + b, 0)
        let engineer = this.props.engineer.reduce((a, b) => a + b, 0)
        let humanities = this.props.humanities.reduce((a, b) => a + b, 0)
        let law = this.props.law.reduce((a, b) => a + b, 0)
        let mass_media = this.props.mass_media.reduce((a, b) => a + b, 0)
        let medical_science = this.props.medical_science.reduce((a, b) => a + b, 0)
        let miscellaneous = this.props.miscellaneous.reduce((a, b) => a + b, 0)
        let pure_sciences = this.props.pure_sciences.reduce((a, b) => a + b, 0)
        let social_work = this.props.social_work.reduce((a, b) => a + b, 0)
        let travel_tourism = this.props.travel_tourism.reduce((a, b) => a + b, 0)
        let array_sum = [civil_services, commerce, defence_police_force, design_fine_arts, education
            , engineer, humanities, law, mass_media, medical_science, miscellaneous, pure_sciences, social_work, travel_tourism]
        console.log(array_sum)
        let dictionary = {}
        dictionary['civil_services'] = civil_services
        dictionary['commerce'] = commerce
        dictionary['defence_police_force'] = defence_police_force
        dictionary['design_fine_arts'] = design_fine_arts
        dictionary['education'] = education
        dictionary['engineer'] = engineer
        dictionary['humanities'] = humanities
        dictionary['law'] = law
        dictionary['mass_media'] = mass_media
        dictionary['medical_science'] = medical_science
        dictionary['miscellaneous'] = miscellaneous
        dictionary['pure_sciences'] = pure_sciences
        dictionary['social_work'] = social_work
        dictionary['travel_tourism'] = travel_tourism
        console.log(dictionary)
        this.setState({
            sumAnswers: array_sum.sort(),
            sumAnswerDirectory: dictionary
        })
    }

    getValueObject = (a) => {
        switch (a) {
            case 'civil_services':
                this.setState({
                    valueObject: ["Discipline", "Communication", "Leadership", "Professionalism and Etiquette", "Problem Solving"]
                })
                break;
            case 'commerce':
                this.setState({
                    valueObject: ["Meticulous", "Management", "Professionalism and Etiquette", "Planning & Organization", "Problem Solving"]
                })
                break;
            case 'defence_police_force':
                this.setState({
                    valueObject: ["Discipline", "Planning & Organization", "Leadership", "Professionalism and Etiquette", "Adaptability"]
                })
                break;
            case 'design_fine_arts':
                this.setState({
                    valueObject: ["Creativity", "Innovation", "Meticulous", "Communication", "Flexibility"]
                })
                break;
            case 'education':
                this.setState({
                    valueObject: ["Adaptability", "Communication", "Planning & Organization", "Management", "Professionalism and Etiquette"]
                })
                break;
            case 'engineer':
                this.setState({
                    valueObject: ["Critical Thinking", "Problem Solving", "Planning & Organization", "Management", "Innovation"]
                })
                break;
            case 'humanities':
                this.setState({
                    valueObject: ["Communication", "Problem Solving", "Creativity", "Critical Thinking", "Meticulous"]
                })
                break;
            case 'law':
                this.setState({
                    valueObject: ["Communication", "Critical Thinking", "Problem Solving", "Meticulous", "Planning & Organization"]
                })
                break;
            case 'mass_media':
                this.setState({
                    valueObject: ["Communication", "Flexibility", "Creativity", "Grit", "Planning & Organization"]
                })
                break;
            case 'medical_science':
                this.setState({
                    valueObject: ["Critical Thinking", "Meticulous", "Communication", "Empathy", "Professionalism and Etiquette"]
                })
                break;
            case 'miscellaneous':
                this.setState({
                    valueObject: ["Grit", "Flexibility", "Creativity", "Communication", "Planning & Organization"]
                })
                break;
            case 'pure_sciences':
                this.setState({
                    valueObject: ["Critical Thinking", "Problem Solving", "Innovation", "Grit", "Planning & Organization"]
                })
                break;
            case 'social_work':
                this.setState({
                    valueObject: ["Empathy", "Communication", "Problem Solving", "Planning & Organization", "Adaptability"]
                })
                break;
            case 'travel_tourism':
                this.setState({
                    valueObject: ["Professionalism and Etiquette", "Communication", "Empathy", "Adaptability", "Problem Solving"]
                })
                break;

        }
    }

    svg2Canvas = (svg, callback) => {
        // //find all svg elements in $container
        // let $container = $('result_body');
        // //$container is the jQuery object of the div that you need to convert to image. This div may contain highcharts along with other child divs, etc
        // let svgElements = $container.find('svg');
        // svgElements.each(function () {
        //     let canvas, xml;
        //     canvas = document.createElement("canvas");
        //     canvas.className = "screenShotTempCanvas";
        //     //convert SVG into a XML string
        //     xml = (new XMLSerializer()).serializeToString(this);
        //     // Removing the name space as IE throws an error
        //     xml = xml.replace(/xmlns=\"http:\/\/www\.w3\.org\/2000\/svg\"/, '');
        //     //draw the SVG onto a canvas
        //     Canvg(canvas, xml);
        //     $(canvas).insertAfter(this);
        //     $(this).hide();

        // });
        let canvas = document.createElement('canvas'); // Create a Canvas element.
        let ctx = canvas.getContext('2d'); // For Canvas returns 2D graphic.
        let data = svg.outerHTML; // Get SVG element as HTML code.
        Canvg(canvas, data); // Render SVG on Canvas.
        callback(canvas); // Execute callback function.

    }

    downloadPdf = () => {
        html2canvas(document.body, {
                allowTaint: true,
                proxy: null,
            }
        ).then((canvas) => {
            let imgData = canvas.toDataURL('image/png');
            const pdf = new jsPDF({
                // orientation : 'landscape',
                unit: 'mm',
                format: [594, 841]
            });

            var width = pdf.internal.pageSize.getWidth();
            var height = pdf.internal.pageSize.getHeight();

            // pdf.addImage(imgData, 'PNG', 0, -6, width, height);
            pdf.addImage(imgData, 'PNG', 0, -3, width, height);
            var imgintwenty = pdf.save("report.pdf");
            console.log(imgData);

        });
    }

    handleClickQuestion = () => {

    }

    componentDidMount() {
        this.calculateSums()
    }

    render() {
        return (
            <div id={"result_body"}>
                <div id="result" className="title">RESULT</div>
                <div className="ruleBox">
                    <div className="textResult">Likhit Unadkat</div>
                    <div className="textResult">Shivaji High School</div>
                    <div className="textResult">Class 8th</div>
                </div>
                <div className="textResult">Your top four Professions are</div>
                <ResultBox
                    option_image='result/Computer Sciences.png'
                    option_title='Engineering'
                    option_description='CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.'
                    priority={0}
                    option_key={114}
                    fun={this.handleClickQuestion}/>
                <ResultBox
                    option_image='result/CFA.png'
                    option_title='Commerce'
                    option_description='CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.'
                    priority={0}
                    option_key={114}
                    fun={this.handleClickQuestion}/>
                <ResultBox
                    option_image='result/Mathematician.png'
                    option_title='Mathematician'
                    option_description='CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.'
                    priority={0}
                    option_key={114}
                    fun={this.handleClickQuestion}/>
                <ResultBox
                    option_image='result/IAS.png'
                    option_title='IAS'
                    option_description='CFA charterholders often work at institutional investment firms, broker-dealers, insurance companies, pension funds, banks, and universities.'
                    priority={0}
                    option_key={114}
                    fun={this.handleClickQuestion}/>
                <div className="textResult">Your Personality Traits are</div>
                <ResultBox
                    option_image='result/Critical Thinking.png'
                    option_title='Critical Thinking'
                    option_description='You can quickly analyze facts and information to form a judgement, guide your belief system, and take action.'
                    priority={0}
                    option_key={114}
                    fun={this.handleClickQuestion}/>
                <ResultBox
                    option_image='result/Problem Solving.png'
                    option_title='Problem Solving'
                    option_description='You can find solutions to difficult and complex problems you might even have never faced before.'
                    priority={0}
                    option_key={114}
                    fun={this.handleClickQuestion}/>
                <ResultBox
                    option_image='result/Planning & Organization.png'
                    option_title='Planning & Organization'
                    option_description='You can quickly think about and list down the tasks that you need to do to achieve your goal.'
                    priority={0}
                    option_key={114}
                    fun={this.handleClickQuestion}/>
                <ResultBox
                    option_image='result/Management .png'
                    option_title='Management'
                    option_description='You have the ability to coordinate the efforts of people and help them reach a common goal.'
                    priority={0}
                    option_key={114}
                    fun={this.handleClickQuestion}/>
                <ResultBox
                    option_image='result/Innovation .png'
                    option_title='Innovation'
                    option_description='You have the ability to imagine a new way to solve a problem and then implement it in reality.'
                    priority={0}
                    option_key={114}
                    fun={this.handleClickQuestion}/>
                <div className="score-board">
                    <button className="playBtn" onClick={this.downloadPdf}>
                        Download Report!
                    </button>
                </div>
            </div>

        );
    }

}

export default Result;
