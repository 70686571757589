import React, {Component} from "react";
import axios from "axios";

class Rules extends Component {

    description_english = "There will be four options for each 35 question."
    rule_one_english = "Choose your most liked option first."
    rule_two_english = "Then choose your second most liked option."
    rule_three_english = "Then choose your third most liked option."
    rule_four_english = "Then choose your least liked option."
    rule_five_english = "Submit each question."
    description_hindi = "प्रत्येक प्रश्न के लिए चार विकल्प होंगे"
    rule_one_hindi = "सबसे पहले अपना सबसे पसंदीदा विकल्प चुनें।"
    rule_two_hindi = "इसके बाद अपना दूसरा सबसे ज्यादा पसंद किया जाने वाला विकल्प चुनें।"
    rule_three_hindi = "इसके बाद अपना तीसरा सबसे ज्यादा पसंद किया जाने वाला विकल्प चुनें।"
    rule_four_hindi = "फिर अपना सबसे कम पसंद किया गया विकल्प चुनें।"
    rule_five_hindi = "प्रत्येक प्रश्न सबमिट करें।"


    startQuiz = () => {
        var url = window.location.href
        console.log(url)
        axios.get('https://probano.herokuapp.com/welcome/4614ada83162eb1e0912159ce915a3a2d972064a/')
            .then(res => {
                var token = res.data['token']
                localStorage.setItem("token", token);
                console.log(res.data['token'])
                this.props.startFun(true)
            })
            .catch(reason => {
                console.log(reason)
                this.props.startFun(false)
            })
    }

    render() {
        return (
            <div>
                <div className="title">RULES</div>
                <div className="ruleBox">
                    <div className="textRules">{this.description_english}</div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_one_english}</div>
                    </div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_two_english}</div>
                    </div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_three_english}</div>
                    </div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_four_english}</div>
                    </div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_five_english}</div>
                    </div>
                    <div className="textRules">{this.description_hindi}</div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_one_hindi}</div>
                    </div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_two_hindi}</div>
                    </div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_three_hindi}</div>
                    </div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_four_hindi}</div>
                    </div>
                    <div className="ruleBulletBox">
                        <div className="dot"/>
                        <div className="textRules">{this.rule_five_hindi}</div>
                    </div>
                    <button className="nextBtn" onClick={this.startQuiz}>Start Quiz</button>
                </div>
            </div>
        )
            ;
    }

}

export default Rules;
